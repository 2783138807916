import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import css from "@emotion/css";
import A from "../components/anchor";

const Portfolio = () => (
  <Layout>
    <SEO title="Portfolio" />
    <div
      css={css`
        h2 {
          margin-top: 32px;
          margin-bottom: 16px;
        }
      `}
    >
      <h1>Portfolio</h1>
      <div>
        I help bring ideas to life. Below you'll find some of the projects that
        I've worked on.
      </div>
      <h2>SC Spheres</h2>
      <div>
        <A href="https://scspheres.com/">SC Spheres</A> is a secure document
        sharing platform. It is a{" "}
        <A href="https://developers.google.com/web/progressive-web-apps">
          progressive web application (PWA)
        </A>{" "}
        with React on the frontend and{" "}
        <A href="https://www.djangoproject.com/">Django</A>,{" "}
        <A href="https://www.docker.com/">Docker</A> and friends on the backend.
      </div>
      <h2>SafePace</h2>
      <div>
        In 2019, <A href="https://triple.black/">Triple Black</A> released{" "}
        <A href="https://triple.black/safepace/">SafePace</A> as a solution that
        allows vulnerable people to move around safer across the country.
      </div>
      <h2>Health Market Inquiry</h2>
      <div>
        I worked on a web application for the{" "}
        <A href="http://www.compcom.co.za/healthcare-inquiry/">HMI</A> (run by
        the <A href="http://www.compcom.co.za/">Competition Commission</A>) that
        allowed the relevant parties to securely manage, share and access the
        various documents involved in the inquiry. This project was run by{" "}
        <A href="https://www.willistowerswatson.com/en-ZA">
          Willis Towers Watson
        </A>
        .
      </div>
      <h2>Sis Joyce</h2>
      <div>
        I developed an AI powered chatbot,{" "}
        <A href="https://www.facebook.com/sisjoycechat/">Sis Joyce</A> for{" "}
        <A href="https://triple.black/">Triple Black</A>. She answers medical
        questions by drawing on a wealth of information available online.
      </div>
      <h2>Voshso</h2>
      <div>
        I worked with <A href="https://triple.black/">Triple Black</A> to build{" "}
        <A href="https://triple.black/vosho/">Vosho</A>, a dance app. You can
        read more about the journey{" "}
        <A href="http://babusinyoni.com/dance-to-diagnosis/">here</A>.
      </div>
      {/* 
      <h2>UNHCR</h2>
      <h2>Jetson</h2> 
      */}
      <h2>Retirement Calculator</h2>
      <div>
        I worked with <A href="https://nmg-group.com">NMG</A> to build a{" "}
        <A href="https://nmg-retirement.sctechnology.co.za/">
          Retirement Calcualtor
        </A>{" "}
        that helps individuals plan for their retirement.
      </div>
      <h2>Medical Aid Assistants</h2>
      <div>
        I worked with{" "}
        <A href="https://www.willistowerswatson.com/en-ZA">
          Willis Towers Watson
        </A>{" "}
        to develop various tools to assist the members of{" "}
        <A href="http://www.medscheme.com/our-clients/nedgroup-medical-aid-scheme/">
          Nedgorup Medical Aid Scheme
        </A>{" "}
        and <A href="https://www.bankmed.co.za">Bankmed</A>. Some examples
        include medical aid plan comparisons, finding a medical practitioner
        near you, medical tariff (ICD-10) code/cost lookups and plan benefit
        lookup tools.
      </div>
      <h2>Health Quality Assessment</h2>
      <div>
        I worked with{" "}
        <A href="https://www.willistowerswatson.com/en-ZA">
          Willis Towers Watson
        </A>{" "}
        to develop a web portal for the <A href="https://www.hqa.co.za/">HQA</A>
        . This portal provides an overview and quarterly updates on the HQA
        indicator values for the various medical aid schemes and their benefit
        options in South Africa.
      </div>
      <h2>Spreadsheet Server</h2>
      <div>
        <A href="https://github.com/robsco-git/spreadsheet_server">
          Spreadsheet Server
        </A>{" "}
        is a python server harnessing the calculational ability of{" "}
        <A href="https://www.libreoffice.org/">LibreOffice</A>{" "}
        <A href="https://www.libreoffice.org/discover/calc/">Calc</A> (thanks to{" "}
        <A href="https://github.com/seznam/pyoo">PyOO</A>). It provides
        'instant' access to the cell ranges of a set of spreadsheets. It has
        been instrumental in facilitating rapid web development by using a
        spreadsheet as the 'brains' of the application.
      </div>
      <h2>My Turn</h2>
      <div>
        I worked on an mobile game, "My Turn" with{" "}
        <A href="https://twitter.com/nextquest_games">Next Question</A> that
        made it to the finals of the{" "}
        <A href="http://seriousaboutgames.co.za">Serious about games</A>{" "}
        competition in 2017.
      </div>
      <h2>HandyBuddy</h2>
      <div>
        In 2017 I worked on{" "}
        <A href="http://babusinyoni.com/handybuddy/">HandyBuddy</A>, a service
        that matches reputable service providers with discerning customers in
        real time.
      </div>
      <h2>Technologies</h2>
      <div>
        Here are some technologies I've enjoyed working with:{" "}
        <A href="https://developer.mozilla.org/en-US/docs/Web/JavaScript">
          JavaScript
        </A>
        , <A href="https://reactjs.org/">React</A>,{" "}
        <A href="https://www.python.org/">Python</A>,{" "}
        <A href="https://www.djangoproject.com/">Django</A>,{" "}
        <A href="https://palletsprojects.com/p/flask/">Flask</A>,{" "}
        <A href="https://www.docker.com/">Docker</A>,{" "}
        <A href="https://developer.mozilla.org/en-US/docs/Web/HTML">HTML</A>,{" "}
        <A href="https://developer.mozilla.org/en-US/docs/Web/CSS">CSS</A>,{" "}
        <A href="https://en.wikipedia.org/wiki/Linux">Linux</A>,{" "}
        <A href="https://ubuntu.com/">Ubuntu</A>,{" "}
        <A href="https://www.php.net/">PHP</A>,{" "}
        <A href="https://www.nginx.com/">NGINX</A>,{" "}
        <A href="https://www.gatsbyjs.org/">GatsbyJs</A>,{" "}
        <A href="https://nodejs.org/">Node.js</A>,{" "}
        <A href="https://en.wikipedia.org/wiki/Bash_(Unix_shell)">Bash</A>,{" "}
        <A href="http://www.celeryproject.org/">Celery</A>,{" "}
        <A href="https://channels.readthedocs.io/en/latest/">Django Channels</A>
        , <A href="https://www.uvicorn.org/">Uvicorn</A>,{" "}
        <A href="https://uwsgi-docs.readthedocs.io/">uWSGI</A>,{" "}
        <A href="https://gunicorn.org/">Gunicorn</A>,{" "}
        <A href="https://www.postgresql.org/">PostgreSQL</A>,{" "}
        <A href="https://www.mysql.com/">MySQL</A>,{" "}
        <A href="https://www.mongodb.com/">MongoDB</A>,{" "}
        <A href="https://electronjs.org/">Electron</A>,{" "}
        <A href="https://emotion.sh/docs/introduction">Emotion</A>,{" "}
        <A href="https://cordova.apache.org/">Cordova</A>, etc.
      </div>
    </div>
  </Layout>
);

export default Portfolio;
